<template>
  <div class="transaction-modal popup">
    <div class="transaction-modal__content modal-content popup__block">
      <div class="modal-content__title content-title">
        {{ $t("referrals_page.how_to_transaction_modal.title") }}
      </div>
      <div class="modal-content__text content-text">
        <div class="content-text__block text-block">
          <div class="text-block__logo">
            <img
              src="@/assets/img/referrals/how-to-transaction/sphere.png"
              alt="sphere"
            />
          </div>
          <div
            class="text-block__title"
            v-html="
              $t(
                'referrals_page.how_to_transaction_modal.connect_apple_google_pay',
                { appleLink, googleLink }
              )
            "
          ></div>
        </div>
        <div class="content-text__block text-block">
          <div class="text-block__logo">
            <img
              src="@/assets/img/referrals/how-to-transaction/coins.png"
              alt="coins"
            />
          </div>
          <div class="text-block__content">
            <div class="text-block__title">
              {{
                $t(
                  "referrals_page.how_to_transaction_modal.first_transaction.title"
                )
              }}
            </div>
            <div class="text-block__subtitle">
              {{
                $t(
                  "referrals_page.how_to_transaction_modal.first_transaction.text"
                )
              }}
            </div>
            <div class="text-block__subtitle">
              {{
                $t(
                  "referrals_page.how_to_transaction_modal.first_transaction.text_2"
                )
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-content__action content-action">
        <primary-colored-button @click="clickHandler">
          {{ $t("referrals_page.how_to_transaction_modal.next") }}
        </primary-colored-button>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryColoredButton from "@/components/button/PrimaryColoredButton";
import { appleGooglePaySetupLinks } from "@/const/referrals";
export default {
  name: "HowToTransaction",
  components: { PrimaryColoredButton },
  methods: {
    clickHandler() {
      this.$emit("closeModal");
    },
  },
  computed: {
    appleLink() {
      return appleGooglePaySetupLinks.apple[this.$i18n.locale];
    },
    googleLink() {
      return appleGooglePaySetupLinks.google[this.$i18n.locale];
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.transaction-modal {
  &__content {
    max-width: 35rem;
  }
}

.modal-content {
}

.content-title {
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  color: $mainBlack;
  margin-bottom: 2.5rem;
}

.content-text {
  display: grid;
  grid-auto-flow: row;
  row-gap: 1rem;
  margin-bottom: 2.5rem;
}

.text-block {
  --logo-width: 3rem;

  padding: 1rem 1.5rem;
  background: $grayLight;
  border-radius: 1rem;
  display: grid;
  grid-template-columns: var(--logo-width) 1fr;
  column-gap: 1.75rem;

  &:first-child {
    align-items: center;

    .text-block__logo {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &:last-child {
    padding: 1.75rem 1.5rem 2.25rem;
  }

  &__title {
    color: $mainBlack;

    &::v-deep a {
      color: $mainCold;
    }
  }

  &__title,
  &__title::v-deep a {
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &__subtitle {
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $gray1;
  }

  &__content {
    display: grid;
    grid-auto-flow: row;
    row-gap: 1.5rem;
  }

  &__logo img {
    width: 100%;
  }
}

@media screen and (max-width: 486px) {
  .transaction-modal {
    &__content {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 5rem;
      padding-top: 4.0625rem;
    }
  }
  .content-text {
    margin-bottom: auto;
  }
  .content-title {
    margin-bottom: 1.875rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .text-block {
    --logo-width: 2rem;

    padding: 1.25rem;
    column-gap: 1.25rem;

    &:last-child {
      padding: 1.5rem 1.25rem 2rem;
    }

    &__content {
      row-gap: 1rem;
    }
  }
}
</style>
