export const shareText = {
  uk: [
    "Відчуйте переваги щоденних криптовалютних платежів з карткою weld від Weld Money вже зараз!\n" +
      "Забирайте власну картку за посиланням, здійснюйте транзакцію та отримуйте 3 USDT на ваш баланс.\n",
    "Я вже користуюся карткою weld і сплачую криптою щодня!\n" +
      "Бажаєш теж? Юзай першу криптовалютну картку в Україні від Weld Money. Реєструйся й отримуй 3 USDT в подарунок.",
  ],
  en: [
    "Experience the benefits of daily cryptocurrency payments with the weld card from Weld Money now!\n" +
      "Take your own card under the link, make a transaction and get 3 USDT on your balance.\n",
    "I already use a weld card and pay with crypto every day!\n" +
      "Do you want it too? Use the first cryptocurrency card in Ukraine by Weld Money. Register and get 3 USDT as a gift.\n",
  ],
};
export const messengersShareText = {
  uk:
    "Привіт! \n" +
    "\n" +
    "Я вже користуюся рішенням українського фінтех стартапу Weld Money для щоденних криптовалютних платежів!\n" +
    "\n" +
    "Цікаво? Миттєво отримуй власну картку weld за посиланням:",
  en:
    "Hi!\n" +
    "\n" +
    "I already use the solution of the Ukrainian fintech startup Weld Money for daily cryptocurrency payments!\n" +
    "\n" +
    "Interesting? Instantly get your own weld card via the link:",
};
