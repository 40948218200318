export const appleGooglePaySetupLinks = {
  apple: {
    uk: "https://support.apple.com/uk-ua/HT204506",
    en: "https://support.apple.com/en-gb/HT204506",
  },
  google: {
    uk: "https://pay.google.com/intl/uk_ua/about/learn/",
    en: "https://pay.google.com/intl/en_uk/about/learn/",
  },
};
