import { ReferralsApi } from "@/office/referrals-api";

export default {
  data() {
    return {
      referralTasks: [],
    };
  },
  methods: {
    async loadReferralTasks() {
      try {
        this.referralTasks = (
          await ReferralsApi.getReferralProgramTasks()
        ).data;
      } catch (e) {
        console.error(
          "Something went wrong when loading referral tasks: \n",
          e
        );
      }
    },
  },
};
