<template>
  <div class="referral-assistant">
    <how-to-transaction
      @closeModal="changeHowToTransactionModalVisibility(false)"
      v-if="isHowToTransactionModalOpen"
    />
    <div class="referral-assistant__title">
      {{ $t("referrals_page.steps.title") }}
    </div>
    <div class="referral-assistant__content content">
      <div class="content-steps">
        <ul class="content-steps__list steps-list">
          <template v-for="(step, index) of formattedTasks">
            <li
              :key="index"
              class="steps-list__item step"
              :class="{ 'step--completed': step.status === 'complete' }"
            >
              <div class="step-icon">
                <img
                  class="step-icon__img"
                  v-if="step.status === 'complete'"
                  src="@/assets/img/step-completed.svg"
                  :alt="
                    $t(`referrals_page.tasks.${step.referral_task_slug}.name`)
                  "
                />

                <span class="step-icon__text" v-else>{{ index + 1 }}</span>
              </div>
              <div class="step-name">
                {{ $t(`referrals_page.tasks.${step.referral_task_slug}.name`) }}
              </div>
            </li>
          </template>
        </ul>
      </div>
      <div class="content-action">
        <div class="content-action__container">
          <div class="content-action__title">
            {{ $t(assistantCurrentStep.title) }}
          </div>
          <div class="content-action__button">
            <primary-colored-button @click="assistantCurrentStep.handler">
              {{ $t(assistantCurrentStep.action) }}
            </primary-colored-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryColoredButton from "@/components/button/PrimaryColoredButton";
import HowToTransaction from "@/components/referral/Popup/HowToTransactionModal";
import loadReferralsTasksMixin from "@/mixins/load-referrals-tasks.mixin";

export default {
  name: "ReferralAssistant",
  components: { HowToTransaction, PrimaryColoredButton },
  mixins: [loadReferralsTasksMixin],
  data() {
    return {
      assistantSteps: [
        {
          referral_task_slug: "registration-complete",
          title: "",
          action: "",
          handler: () => {},
        },
        {
          referral_task_slug: "card-created",
          title: "referrals_page.assistant.open_card.title",
          action: "referrals_page.assistant.open_card.action",
          handler: this.openCard,
        },
        {
          referral_task_slug: "wallet-added",
          title: "referrals_page.assistant.connect_exchange.title",
          action: "referrals_page.assistant.connect_exchange.action",
          handler: this.connectExchange,
        },
        {
          referral_task_slug: "transaction-complete",
          title: "referrals_page.assistant.transaction.title",
          action: "referrals_page.assistant.transaction.action",
          handler: this.transaction,
        },
      ],
      isHowToTransactionModalOpen: false,
    };
  },
  async created() {
    await this.loadReferralTasks();
  },
  computed: {
    assistantCurrentStep() {
      let _activeStep = this.findActiveStep();

      if (!_activeStep) {
        return this.assistantSteps[0];
      }

      return this.assistantSteps.find(
        (_aS) => _aS.referral_task_slug === _activeStep.referral_task_slug || ""
      );
    },
    formattedTasks() {
      let _tasks = this.referralTasks.filter(
        (t) => t.referral_task_slug !== "registration-complete"
      );

      let _transactionCompleteTaskIndex = _tasks.findIndex(
        (t) => t.referral_task_slug === "transaction-complete"
      );

      if (_tasks.length > 0) {
        _tasks.splice(_transactionCompleteTaskIndex, 0, {
          status: "new",
          referral_task_slug: "card-services",
        });
      }

      return _tasks;
    },
  },
  methods: {
    changeHowToTransactionModalVisibility(value) {
      this.isHowToTransactionModalOpen = value;
    },
    findActiveStep() {
      let _currentTaskIndex = [...this.referralTasks]
        .sort((t1, t2) => (t1.ord > t2.ord ? 1 : t1.ord === t2.ord ? 0 : -1))
        .findIndex((t) => t.status === "new");

      _currentTaskIndex =
        _currentTaskIndex > -1
          ? _currentTaskIndex
          : this.referralTasks.length - 1;

      return this.referralTasks[_currentTaskIndex];
    },
    async openCard() {
      await this.$router.push({
        path: "/cards",
        query: {
          start_add_card: true,
        },
      });
    },
    async connectExchange() {
      await this.$router.push({
        path: "/wallets",
        query: {
          add_wallet: true,
        },
      });
    },
    transaction() {
      this.changeHowToTransactionModalVisibility(true);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.referral-assistant {
  border-radius: 1.5rem;
  background-color: $gray3;
  padding: 2.5rem 2.5rem 3.5rem;

  --sections-gap: 2.5rem;

  &__title {
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  &__container {
    display: grid;
    grid-auto-flow: row;
    row-gap: 2.5rem;
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr 30%;
  column-gap: calc(var(--sections-gap) * 2);

  &-steps {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: calc(100% + var(--sections-gap));
      top: 0;
      height: 100%;
      width: 1px;
      background-color: $gray2;
    }
  }
}

.steps-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 2.5rem;
  column-gap: 2.5rem;
  justify-content: space-between;
}

.step {
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;
  align-items: center;
  width: fit-content;

  &--completed {
  }

  &-icon {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: $grayLight;

    &__text {
      font-family: "Rubik", sans-serif;
      font-weight: 400;
      font-size: 1.625rem;
      line-height: 2rem;
      color: $mainCold;
    }

    &__img {
      width: 100%;
      height: 100%;
    }
  }

  &-name {
    font-family: "Nunito", sans-serif;
    font-weight: 400;
    color: $mainBlack;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.content-action {
  height: 100%;

  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $mainBlack;
    font-weight: 400;
  }

  &__button {
    width: 100%;
  }
}

@media screen and (max-width: 486px) {
  .referral-assistant {
    padding: 2.5rem 1.5rem 3rem;

    &__title {
      font-size: 1.25rem;
    }
  }
  .content {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    row-gap: calc(var(--sections-gap) * 2);

    &-steps {
      &::after {
        left: 0;
        width: 100%;
        height: 1px;
        top: calc(100% + var(--sections-gap));
      }
    }
  }
  .steps-list {
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
  }
  .step {
    &-icon {
      width: 2rem;
      height: 2rem;

      &__text {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
    }
  }
  .content-action {
    &__container {
      display: grid;
      grid-auto-flow: row;
      row-gap: 1.5rem;
    }

    &__title {
    }
  }
}
</style>
