var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"transaction-modal popup"},[_c('div',{staticClass:"transaction-modal__content modal-content popup__block"},[_c('div',{staticClass:"modal-content__title content-title"},[_vm._v(" "+_vm._s(_vm.$t("referrals_page.how_to_transaction_modal.title"))+" ")]),_c('div',{staticClass:"modal-content__text content-text"},[_c('div',{staticClass:"content-text__block text-block"},[_vm._m(0),_c('div',{staticClass:"text-block__title",domProps:{"innerHTML":_vm._s(
            _vm.$t(
              'referrals_page.how_to_transaction_modal.connect_apple_google_pay',
              { appleLink: _vm.appleLink, googleLink: _vm.googleLink }
            )
          )}})]),_c('div',{staticClass:"content-text__block text-block"},[_vm._m(1),_c('div',{staticClass:"text-block__content"},[_c('div',{staticClass:"text-block__title"},[_vm._v(" "+_vm._s(_vm.$t( "referrals_page.how_to_transaction_modal.first_transaction.title" ))+" ")]),_c('div',{staticClass:"text-block__subtitle"},[_vm._v(" "+_vm._s(_vm.$t( "referrals_page.how_to_transaction_modal.first_transaction.text" ))+" ")]),_c('div',{staticClass:"text-block__subtitle"},[_vm._v(" "+_vm._s(_vm.$t( "referrals_page.how_to_transaction_modal.first_transaction.text_2" ))+" ")])])])]),_c('div',{staticClass:"modal-content__action content-action"},[_c('primary-colored-button',{on:{"click":_vm.clickHandler}},[_vm._v(" "+_vm._s(_vm.$t("referrals_page.how_to_transaction_modal.next"))+" ")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-block__logo"},[_c('img',{attrs:{"src":require("@/assets/img/referrals/how-to-transaction/sphere.png"),"alt":"sphere"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-block__logo"},[_c('img',{attrs:{"src":require("@/assets/img/referrals/how-to-transaction/coins.png"),"alt":"coins"}})])
}]

export { render, staticRenderFns }