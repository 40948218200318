import { socialMedia } from "./social-media";

export const shareLinks = {
  [socialMedia.facebook]:
    "https://www.facebook.com/sharer/sharer.php?u=$URL&t=$TITLE",
  [socialMedia.twitter]:
    "https://twitter.com/intent/tweet?url=$URL&text=$TITLE",
  [socialMedia.telegram]: "https://t.me/share/url?url=$URL&text=$TITLE",
  [socialMedia.reddit]: "https://reddit.com/submit?url=$URL&title=$TITLE",
  [socialMedia.whatsapp]: "https://api.whatsapp.com/send?text=$TITLE",
};
