import telegramIcon from "@/assets/img/social/telegram.svg";
import twitterIcon from "@/assets/img/social/twitter.svg";
import facebookIcon from "@/assets/img/social/facebook.svg";
import redditIcon from "@/assets/img/social/reddit.svg";
import whatsappIcon from "@/assets/img/social/whatsapp.svg";
import nativeIcon from "@/assets/img/social/native.svg";

export const socialMedia = {
  facebook: "facebook",
  twitter: "twitter",
  telegram: "telegram",
  reddit: "reddit",
  whatsapp: "whatsapp",
};

export const socialMediaIcons = {
  [socialMedia.telegram]: telegramIcon,
  [socialMedia.twitter]: twitterIcon,
  [socialMedia.facebook]: facebookIcon,
  [socialMedia.reddit]: redditIcon,
  [socialMedia.whatsapp]: whatsappIcon,
  native: nativeIcon,
};

export const socialMediaList = [
  {
    name: socialMedia.telegram,
    icon: socialMediaIcons[socialMedia.telegram],
    visibleOnMobile: true,
    visibleOnDesktop: true,
  },
  {
    name: socialMedia.twitter,
    icon: socialMediaIcons[socialMedia.twitter],
    visibleOnMobile: true,
    visibleOnDesktop: true,
  },
  {
    name: socialMedia.whatsapp,
    icon: socialMediaIcons[socialMedia.whatsapp],
    visibleOnMobile: false,
    visibleOnDesktop: true,
  },
  {
    name: socialMedia.facebook,
    icon: socialMediaIcons[socialMedia.facebook],
    visibleOnMobile: true,
    visibleOnDesktop: true,
  },
  {
    name: socialMedia.reddit,
    icon: socialMediaIcons[socialMedia.reddit],
    visibleOnMobile: false,
    visibleOnDesktop: true,
  },
  {
    name: "native",
    icon: socialMediaIcons.native,
    visibleOnMobile: true,
    visibleOnDesktop: false,
  },
];
